import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PageContainer'
import PageContainer from './PageContainer';

function ConfirmationPage() {
    const navigate = useNavigate();
    
    useEffect(() => {
        // Fire off the Google conversion event
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-11430476234/AQ-pcKT554gZEMrzvMoq'
          });
        }
      }, []);

    if (process.env.NODE_ENV === 'development') {
		const googleResponse = JSON.parse(localStorage.getItem('googleResponse'));
		if (!googleResponse) {
			navigate('/');
		}
	}

    return (
        <PageContainer>
            <div className='inner-container'>
                <h1>Thank you for your order!</h1>
                <div className='content'>
                    <p>
                        I'll send you an email when your analysis is ready. It may take
                        up to 24 hours for me to complete your order. You won't be charged
                        until I'm finished. 
                    </p>
                </div>
            </div>
        </PageContainer>
    );
}

export default ConfirmationPage;