import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TypingEffect from './TypingEffect';
import './global.css';
import PageContainer from './PageContainer';

function UploadPage() {
    const [step, setStep] = useState(1);
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [file, setFile] = useState(null);
    const [quote, setQuote] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [workTitle, setWorkTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [orderToken, setOrderToken] = useState(null);
    const [promoCode, setPromoCode] = useState('');
    const [promoUsed, setPromoUsed] = useState(false);
    const navigate = useNavigate();
    const backendURL = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
	const SPEED = 25;

    const [quoteFailed, setQuoteFailed] = useState(false);

	// If this is a development instance, check for the oauth response in local storage
	//  and redirect to the login page if it doesn't exist.
	if (process.env.NODE_ENV === 'development') {
		const googleResponse = JSON.parse(localStorage.getItem('googleResponse'));
		if (!googleResponse) {
			navigate('/');
		}
	}

    useEffect(() => {
		fetch(`${backendURL}/services`)
			.then(response => response.json())
			.then(data => {
				// Assuming that the data received is an array of service objects
				setServices(data);
			})
			.catch(error => console.error('Error fetching services:', error));
	}, [backendURL]);

    useEffect(() => {
        if (quote < 0) {
            setQuoteFailed(true);
        }
        // reset quoteFailed when quote changes to a non-negative value
        else if (quote >= 0) {
            setQuoteFailed(false);
        }
    }, [quote]);
	
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleNextStep = () => {
        setStep(currentStep => {
            // If this is the first step, log a form start with the backend
            if (currentStep === 1) {
                const logFormData = new FormData();
                logFormData.append('api_key', apiKey);
                fetch(`${backendURL}/log_start`, {
                    method: 'POST',
                    body: logFormData
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Logged form start');
                    })
                    .catch(error => console.error('Error logging form start', error))
            }
        

            const nextStep = currentStep + 1;
            // Validate current step
            if ((currentStep === 1 && author.trim() === '') ||
                (currentStep === 2 && workTitle.trim() === '') ||
                (currentStep === 3 && !validateEmail(email)) ||
                (currentStep === 4 && !file) ||
                (currentStep === 5 && selectedServices.length === 0)) {
                setError(true);
            } else {
                setError(false);
                // Call fetchQuote only when moving from step 5 to step 6
                if (currentStep === 5) {
                    if (!isLoading)
                        fetchQuote();
                }
                if (currentStep < 7) {
                    return nextStep;
                }
            }
            return currentStep; // Return the current step if there's an error or the step is greater than 7
        });
    };
    

    const handlePrevStep = () => {
		if (step > 5) {
			setStep(5);
		}
        else if (step > 1) {
            setStep(step => step - 1);
        }
		else {
			navigate('/')
		}
    };

    const handleFileChange = (event) => {
        // Make sure the file is a .docx file
        if (!event.target.files[0].name.endsWith('.docx')) {
            alert('File must be a .docx file.')
            setError(true);
            return;
        }

        setFile(event.target.files[0]);
		// Advance to next step
		setStep(step => step + 1);
        setError(false);
    };

	const handleServiceChange = (selectedService) => {
		setSelectedServices((prevSelectedServices) => {
			const newSelectedServices = new Set(prevSelectedServices);
	
			if (newSelectedServices.has(selectedService.name)) {
				// Check if this service is a dependency of any other selected service
				let dependentServices = services.filter((service) =>
					service.dependencies.includes(selectedService.name) &&
					newSelectedServices.has(service.name)
				).map(service => service.name);
	
				if (dependentServices.length > 0) {
					// Alert the user that this service is a dependency and cannot be deselected.
					alert("Some services require others.");
					return prevSelectedServices; // Return the previous state without any changes.
				} else {
					newSelectedServices.delete(selectedService.name);
				}
			} else {
				// If selecting, add the service and its dependencies
				newSelectedServices.add(selectedService.name);
				selectedService.dependencies.forEach((dependency) => {
					newSelectedServices.add(dependency);
				});
			}
	
			return Array.from(newSelectedServices);
		});
	};
	
	

    const fetchQuote = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('work_title', workTitle);
        formData.append('author', author);
        formData.append('user_id', email);
        formData.append('services', selectedServices.join(','));
        formData.append('api_key', apiKey);
        formData.append('promo', promoCode);

        fetch(`${backendURL}/quote`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.quote && data.quote < 0) {
                    setQuoteFailed(true);
                    setQuote(-1);
                    console.log('Quote failed:', data.quote);
                    setPromoUsed(false);
                    handleNextStep();
                }

                if (data.status === "success" && data.task_id) {
                    pollQuoteStatus(data.task_id);
                } else {
                    setQuoteFailed(true);
                    setQuote(-1);
                    setIsLoading(false);
                    setPromoUsed(false);
                    handleNextStep();
                }
            })
            .catch(error => {
                console.error('Error fetching quote:', error);
                setIsLoading(false);
                setQuote(-1);
                setQuoteFailed(true);
                setPromoUsed(false);
                handleNextStep();
            });

        // Log that the user has gotten a quote
        const logFormData = new FormData();
        logFormData.append('api_key', apiKey);
        fetch(`${backendURL}/log_quote`, {
            method: 'POST',
            body: logFormData
        })
            .then(response => response.json())
            .then(data => {
                console.log('Logged quote');
            })
            .catch(error => console.error('Error logging quote', error));
    };

    const pollQuoteStatus = (taskId) => {
        setTimeout(() => {
            fetch(`${backendURL}/quote/status/${taskId}`)
                .then(response => response.json())
                .then(data => {
                    if (data.status === "complete") {
                        setQuote(data.result.price);
                        setOrderToken(data.result.order_token);
                        setIsLoading(false);
                        setPromoUsed(data.result.promo_used);
                        handleNextStep(); // Move to next step after quote is received
                    } else if (data.status === "pending") {
                        pollQuoteStatus(taskId); // Continue polling
                    } else {
                        setIsLoading(false);
                        setQuoteFailed(true);
                        setQuote(-1);
                        setPromoUsed(false);
                        handleNextStep();
                    }
                })
                .catch(error => {
                    console.error('Error checking quote status:', error);
                    setIsLoading(false);
                    setQuoteFailed(true);
                    setPromoUsed(false);
                    setQuote(-1);
                });
        }, 1000);
    };

    const handleSubmit = () => {
        navigate('/checkout', {
            state: {
                quote: quote,
                orderToken: orderToken,
                user_id: email,
                services: selectedServices
            }
        });
    };

    const renderInputField = (type, value, onChange, placeholder) => {
        return (
            <input
                type={type}
                value={value}
                onChange={onChange}
                onBlur={handleNextStep}
                onKeyDown={(e) => e.key === 'Enter' && handleNextStep()}
                className={error ? 'input-error' : ''}
                placeholder={placeholder}
            />
        );
    };

    const makeQuoteString = (quote, promoCode, promoUsed) => {
        console.log(quote)
        // If the quote is negative, the backend has rejected the file
        if (quote < 0) {
            return "I'm sorry, but I can't process that file. Please ensure your document has proper section/chapter headings and try again. If you have continued difficulty, please email your document to help@calliope.tech so I can improve my parsing system. If you do, you may receive a free full-service analysis!";
        }

        var price = `${quote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        if (promoCode && promoUsed) {
            // The user supplied a promo code and it was successfully applied
            return `Your quote is: $${price}. Your promo code was successfully applied. Ready to proceed to checkout?`;
        }
        else if (promoCode && !promoUsed) {
            // The user supplied an expired or invalid promo code
            return `Your quote is: $${price}. Your promo code was not applied (it was invalid or expired). Ready to proceed to checkout?`;
        }
        else { 
            return `Your quote is: $${price}. Ready to proceed to checkout?`
        }
    }

    const renderStepContent = () => {
        console.log('step:', step)

        if (step > 7) {
            setStep(7);
        }

        switch (step) {
            case 1:
                return (
                    <div>
                        <TypingEffect key={step} textArray={["What's your name (or pseudonym)?"]} speed={`${SPEED}`} />
                        {renderInputField("text", author, (e) => setAuthor(e.target.value), "Author Name")}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <TypingEffect key={step} textArray={[`Hi, ${author}! What's the title of your work?`]} speed={`${SPEED}`} />
                        {renderInputField("text", workTitle, (e) => setWorkTitle(e.target.value), "Work Title")}
                    </div>
                );
            case 3:
                return (
                    <div>
                        <TypingEffect key={step} textArray={[`I can't wait to read ${workTitle}! I just need your email address. I'll use it to send you my work.`]} speed={`${SPEED}`} />
                        {renderInputField("email", email, (e) => setEmail(e.target.value), "Email Address")}
                    </div>
                );
            case 4:
                return (
                    <div>

                        <TypingEffect key={step} textArray={["Please upload your work. (I need a .docx file)"]} speed={`${SPEED}`} />
                        <input type="file" onChange={handleFileChange} className={error ? 'input-error' : ''} />
                    </div>
                );
                case 5:
                    return (
                        <div>
                            <TypingEffect key={step} textArray={["Great! What services would you like? (You can hover over each option to see what it does.)"]} speed={SPEED} />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {services.map(service => (
                                    <div key={service.name} style={{ margin: '0 10px' }}>
                                        <label title={service.description}>
                                            <input 
                                                type="checkbox"
                                                checked={selectedServices.includes(service.name)}
                                                onChange={() => handleServiceChange(service)}
                                            />
                                            {service.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <input
                                type="text"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                                placeholder="Enter promo code"
                                className={error ? 'input-error' : ''}
                            />
                        </div>
                    );
            case 6:
                return (
                    <div>
                        <TypingEffect key={step} textArray={["I'm calculating your quote, please wait..."]} speed={`${SPEED}`} />
                        {isLoading && <div className="loader"></div>}
                    </div>
                );
            case 7:
                return (
                    <div>
						<TypingEffect key={step} textArray={[makeQuoteString(quote, promoCode, promoUsed)]} speed={`${SPEED}`} />
                    </div>
                );
            default:
                return null;
        }
    };

	const renderNextButton = () => {
        if (step === 6 && isLoading) {
            return null; // or a loading indicator, if preferred
        }

        // Only render the Next or Proceed to Checkout button if not on the first step or after the final step
        if (step < 7) {
            return (
                <button onClick={handleNextStep} className="next-button">
                    Next
                </button>
            );
        } else if (step === 7 && !quoteFailed){
            return (
                <button onClick={handleSubmit} className="next-button">
                    Proceed to Checkout
                </button>
            );
        }
        else {
            return null;
        }
    };

    return (
        <PageContainer>
			<div className="inner-container">
				<div className="back-button-container">
					<button onClick={handlePrevStep} className="back-button">Back</button>
				</div>
				<div className="content">
					{renderStepContent()}
				</div>
                <div className="next-button-container">
				{renderNextButton()}
                </div>
			</div>
        </PageContainer>
    );
}

export default UploadPage;
