import React, { useState, useEffect } from 'react';

const TypingEffect = ({ textArray, speed, resetKey }) => {
    const [activeTextIndex, setActiveTextIndex] = useState(0);
    const [displayText, setDisplayText] = useState('');
    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {
        let timeoutId;

        // Only start typing if 'isTyping' is true
        if (isTyping) {
            if (activeTextIndex < textArray.length) {
                if (displayText.length < textArray[activeTextIndex].length) {
                    timeoutId = setTimeout(() => {
                        setDisplayText(textArray[activeTextIndex].substring(0, displayText.length + 1));
                    }, speed);
                } else {
                    timeoutId = setTimeout(() => {
                        setActiveTextIndex(activeTextIndex + 1);
                        setDisplayText('');
                    }, speed * 30); // Wait a bit longer at the end of a paragraph
                }
            } else {
                setIsTyping(false); // Stop typing when all text is displayed
            }
        }

        return () => clearTimeout(timeoutId);
    }, [displayText, textArray, speed, activeTextIndex, isTyping]);

    // Reset the typing effect when the 'resetKey' changes
    useEffect(() => {
        setActiveTextIndex(0);
        setDisplayText('');
        setIsTyping(true); // Re-enable typing
    }, [resetKey]);

    return (
        <div>
            {textArray.slice(0, activeTextIndex + 1).map((paragraph, index) => (
                <p key={index}>
                    {index === activeTextIndex ? displayText : paragraph}
                </p>
            ))}
        </div>
    );
};

export default TypingEffect;
