import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DropIn from 'braintree-web-drop-in-react';
import './global.css';
import './PageContainer'
import PageContainer from './PageContainer';

function CheckoutPage() {
    const [clientToken, setClientToken] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dropInInstance = useRef(null);
    const { quote, orderToken, user_id, services } = location.state;
    const backendURL = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    if (process.env.NODE_ENV === 'development') {
		const googleResponse = JSON.parse(localStorage.getItem('googleResponse'));
		if (!googleResponse) {
			navigate('/');
		}
	}

    console.log(location.state)
    console.log('quote:', quote);
    console.log('orderToken:', orderToken);
    console.log('user_id:', user_id);

    useEffect(() => {
        const formData = new FormData();
        formData.append('api_key', apiKey);
        fetch(`${backendURL}/client_token`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                setClientToken(data.client_token);
            })
            .catch(error => console.error('Error fetching client token:', error));
    }, [backendURL, apiKey]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const { nonce } = await dropInInstance.current.requestPaymentMethod();

            const formData = new FormData();
            formData.append('api_key', apiKey);
            formData.append('user_id', user_id);
            formData.append('order_token', orderToken);
            formData.append('payment_nonce', nonce);

            const response = await fetch(`${backendURL}/submit_order`, {
                method: 'POST',
                body: formData
            });
            const data = await response.json();

            if (data.status === "success") {
                navigate('/confirmation');
            } else {
                // Handle unsuccessful submission
                alert("Order submission failed.");
            }
        } catch (error) {
            console.error('Error processing payment:', error);
        }
    };

    // Parse the services array into a string
    let servicesString = "";
    for (let i = 0; i < services.length; i++) {
        if (i === 0) {
            servicesString += services[i];
        } else {
            servicesString += ", " + services[i];
        }
    }

    if (!clientToken) return <div>Loading...</div>;

    return (
        <PageContainer>
            <div className="inner-container">
                <div className='checkout-summary'>
                    <div className='header'>
                        <h1>Checkout</h1>
                    </div>
                    <p>Price: ${quote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    <p>Services: {servicesString}</p>
                    <p>Delivered to: {user_id}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <DropIn
                        options={{ authorization: clientToken }}
                        onInstance={(instance) => (dropInInstance.current = instance)}
                    />
                    <button className="next-button" type="submit">Place Order</button>
                </form>
            </div>
        </PageContainer>
    );
}

export default CheckoutPage;