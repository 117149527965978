import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

const isDevelopment = true;

const LoginPage = () => {
    const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';
    const navigate = useNavigate();

    console.log(isDevelopment);

    useEffect(() => {
        if (!isDevelopment) {
            console.log("Redirecting to welcome page");
            navigate('/welcome');
        }
    }, [isDevelopment, navigate]); // The useEffect will run after the component mounts

    const responseGoogle = (response) => {
        // Stash the response in local storage
        console.log("Got oauth response")
        localStorage.setItem('googleResponse', JSON.stringify(response));
        // Route to the welcome page
        navigate('/welcome');
    };

    if (isDevelopment) {
        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_OATH_CLIENT_ID}>
                <GoogleLogin
                    onSuccess={(credentialResponse) => responseGoogle(credentialResponse)}
                    onFailure={() => console.log("Login failed")}
                />
            </GoogleOAuthProvider>
        );
    } else {
        return null
    }
};

export default LoginPage;
