import React from 'react';

function PageContainer({ children }) {
    return (
        <div className="page-container">
            <div className="logo-container">
                <img src="/calliope.png" alt="Calliope" />
            </div>
            {children}
        </div>
    );
}

export default PageContainer;