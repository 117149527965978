import React, { useEffect, useState } from 'react';
import styles from './global.css';
import TypingEffect from './TypingEffect';
import PageContainer from './PageContainer';
import Tab from './Tab';
import TagManager from 'react-gtm-module';

const typeSpeed = 25;

function LandingPage() {
    const [activeTab, setActiveTab] = useState('home');
    const [headerText, setHeaderText] = useState('Hi! I\'m Calliope.');
    const [canAcceptOrders, setCanAcceptOrders] = useState(false);
    const backendURL = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    const tagManagerArgs = {
        gtmId: 'AW-11430476234'
    }

    TagManager.initialize(tagManagerArgs);

    const navigateToTos = () => {
        if (!canAcceptOrders) {
            alert('Sorry, my servers are currently at capacity. Please try again later.')
            return;
        }
        window.location.href = '/tos';
    };

    // Check if the backend can accept new orders
    useEffect(() => {
        fetch(`${backendURL}/can_accept_orders`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(data => {
                setCanAcceptOrders(data.can_accept_orders);
            })
            .catch(error => console.error('Error checking order acceptance', error));
    }
    , [backendURL, apiKey]);

    // Log this loading of the app w/ the backend
    const formData = new FormData();
    formData.append('api_key', apiKey);
    useEffect(() => {   
        fetch(`${backendURL}/log_landing`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('Logged landing');
            })
            .catch(error => console.error('Error logging landing', error));
    }
    , [backendURL, apiKey]);

    // If this is a development instance, check for the oauth response in local storage
	//  and redirect to the login page if it doesn't exist.
	if (process.env.REACT_APP_ENVIRONMENT === 'development') {
		const googleResponse = JSON.parse(localStorage.getItem('googleResponse'));
        const authenticated = googleResponse;
		if (!authenticated) {
			window.location.href = '/';
        }
    }

    const tabs = [
        { id: 'faq', title: 'About' },
        { id: 'pricing', title: 'Pricing' },
        { id: 'privacy', title: 'Privacy' },
        { id: 'help', title: 'Help'}
    ];

    const renderTabContent = () => {
        switch (activeTab) {
            case 'faq':
                return <div className={styles.welcomeMessage}>
                        <TypingEffect textArray={["I'm Calliope, where the art of your storytelling meets the precision of state-of-the-art AI analysis. As a dedicated literary reviewer, I delve deep into the heart of your novel, offering services that range from detailed summarization to comprehensive critiques, insightful reader notes, expert synopsis and pitch writing, to nuanced market analysis.",
                                                  "Consider me your first reader and advisor—the one who helps shape your draft before it's ready to charm agents or captivate publishers. My role is to augment the traditional editing process, providing you with a foundation of expert insights that can illuminate the path to your revision or submission strategy.",
                                                  "Here’s how we collaborate: Once you upload your document to my care, I get to work, wrapping up a full suite of feedback within 24 hours, delivered straight to your inbox.",
                                                  "For our partnership to thrive, please format your manuscript with clear, well-defined headers (which you can set up using your document editor's toolbar). A well-structured document, especially one with distinct chapter breaks, allows me to comprehend and analyze your narrative effectively."]} speed={typeSpeed} resetKey={activeTab}/>
                </div>;
            case 'pricing':
                return <div className={styles.welcomeMessage}>
                    <TypingEffect textArray={["The price for my services is charged per document and is a function of word count and how busy I am. Prices are orders of magnitude lower than paying a human for similar work. 'Get Started' for a precise quote."]} speed={typeSpeed} resetKey={activeTab}/>
                </div>;
            case 'privacy':
                return <div className={styles.welcomeMessage}>
                    <TypingEffect textArray={["I take the protection of your intellectual property very seriously. I will never share, sell, or otherwise disclose your work or information to a third party."]} speed={typeSpeed} resetKey={activeTab}/>
                </div>;
            case 'help':
                return <div className={styles.welcomeMessage}>
                    <TypingEffect textArray={["If you need help with an existing order, please email help@calliope.tech from the email on file with your order."]} speed={typeSpeed} resetKey={activeTab}/>
                </div>;
            case 'home':
            default:
                console.log("Environment:", process.env.REACT_APP_ENVIRONMENT);
                console.log("Backend URL:", backendURL);
                return (
                    <div className={styles.welcomeMessage}>
                        <TypingEffect textArray={["Your AI literary reviewer. Upload your manuscript and, within 24 hours, receive an editor’s suite of critiques, synopses, and reader insights to elevate your writing to new heights."]} speed={typeSpeed} resetKey={activeTab}/>
                    </div>
                );
        }
    };

    return (
        <PageContainer>
            <div className="inner-container">
                <div className="header">
                    <TypingEffect textArray={[headerText]} speed="50" resetKey={activeTab}/>
                </div>
                <div className="content">
                    {renderTabContent()}
                </div>
                <div className="tabNavigation">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            active={activeTab === tab.id}
                            onClick={() => {
                                setActiveTab(tab.id);
                                setHeaderText(tab.title);
                            }}
                        >
                            {tab.title}
                        </Tab>
                    ))}
                    <button className={`${styles.button} ${styles.getStartedButton}`} onClick={navigateToTos}>
                        Get Started
                    </button>
                </div>
            </div>
        </PageContainer>
    );
}

export default LandingPage;