import React from 'react';
import styles from './global.css';

const Tab = ({ children, active, onClick }) => {
    return (
        <div onClick={onClick}>
            {children}
        </div>
    );
};

export default Tab;
