import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageContainer from './PageContainer';
import TypingEffect from './TypingEffect';

function Tos() {
    const navigate = useNavigate();

    if (process.env.NODE_ENV === 'development') {
		const googleResponse = JSON.parse(localStorage.getItem('googleResponse'));
		if (!googleResponse) {
			navigate('/');
		}
	}

    const navigateToUpload = () => {
        if (!document.querySelector('input[type="checkbox"]').checked) {
            return;
        }
        navigate('/upload');
    }

    return (
        <PageContainer>
            <div className='inner-container'>
                <h1>
                    <TypingEffect textArray={["Terms of Service"]} speed="25" />
                </h1>
                <div className='content'>
                    <TypingEffect textArray={["Before we start, I need you to agree to my terms of service."]} speed="25" />
                    <input 
                        label="I agree to the Calliope Terms of Service"
                        type="checkbox"
                        onChange={() => {}}
                    />
                    <a> By checking the box, I agree to the Calliope </a>
                    <a href="https://development.calliope.tech/terms_of_service.html">Terms of Service</a>
                    <a> and </a>
                    <a href="https://development.calliope.tech/privacy_policy.html">Privacy Policy</a>
                </div>
                <button className="button" onClick={navigateToUpload}>Next</button>
            </div>
        </PageContainer>
    );
}

export default Tos;